<template>
  <main>
    <div class="hero_single version_2">
      <div class="opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.6)">
        <div class="container">
          <div class="row justify-content-lg-start justify-content-md-center">
            <div class="col-xl-7 col-lg-8">
              <h1>Comandă simplu și rapid</h1>
              <!-- <p>Cele mai bune restaurante cu cele mai bune preturi</p> -->
              <form>
                <div class="row no-gutters custom-search-input">
                  <div class="col-lg-10">
                    <div class="form-group">
                      <b-input
                          type="text"
                          v-model="searchTerm"
                          class="form-control no_border_r"
                          @keyup.enter="search"
                          placeholder="Orașul sau numele restaurantului...">
                      </b-input>
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <button class="btn_1 gradient" @click="search" type="submit">Caută</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <!-- /row -->
        </div>
      </div>
      <div class="wave hero"></div>
    </div>
    <!-- /hero_single -->

    <div class="container">
      <div class="main_title center" style="margin-bottom: 0px">
        <h2 style="margin-top: 0px">Alege un oraș pentru a vizualiza restaurantele</h2>
      </div>
    </div>

    <div class="container margin_30_20">
      <b-row class="cities-wrapper">
        <b-col md="4" sm="12" v-for="(city, index) in cities" :key="index">
          <div class="strip">
            <figure>
              <b-img :src="'/cities/' + city.slug + '.jpg'" fluid-grow></b-img>
              <router-link class="strip_info" :to="{ name: 'public.city.details', params: { slug: city.slug } }">
                <div class="item_title">
                  <h3>{{ city.name }}</h3>
                </div>
              </router-link>
            </figure>
          </div>
        </b-col>
      </b-row>
    </div>

    <div class="bg_gray" v-if="restaurants.length > 0">
      <div class="container margin_60_40">
        
        <!-- /row -->
        <div class="banner lazy" data-bg="url(img/banner_bg_desktop.jpg)" data-was-processed="true"
             style="background-image: url(&quot;img/banner_bg_desktop.jpg&quot;);">
          <div class="wrapper d-flex align-items-center opacity-mask" data-opacity-mask="rgba(0, 0, 0, 0.3)"
               style="background-color: rgba(0, 0, 0, 0.3);">
            <div>
              <small>Coressy delivery</small>
              <h3>Livrăm și la tine la birou</h3>
              <p>Savurează preparatele delicioase la doar câteva minute de la comandă</p>
              <a href="/restaurante" class="btn_1 gradient">Comandă acum</a>
            </div>
          </div>
          <!-- /wrapper -->
        </div>
        <!-- /banner -->
      </div>
    </div>

    <div class="shape_element_2">
      <div class="container margin_60_0">
        <div class="row">
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6">
                <div class="box_how">
                  <figure><img src="img/how_1.svg" data-src="img/how_1.svg" alt="" width="150"
                               height="167" class="lazy"></figure>
                  <h3>Comandă rapid</h3>
                  <p>Alege produsele dorite și comandă cu un singur click</p>
                </div>
                <div class="box_how">
                  <figure><img src="img/how_2.svg" data-src="img/how_2.svg" alt="" width="130"
                               height="145" class="lazy"></figure>
                  <h3>Livrare rapidă</h3>
                  <p>Termenul de livrare este de maxim 60 de minute din momentul comenzii.</p>
                </div>
              </div>
              <div class="col-lg-6 align-self-center">
                <div class="box_how">
                  <figure><img src="img/how_3.svg" data-src="img/how_3.svg" alt="" width="150"
                               height="132" class="lazy"></figure>
                  <h3>Pofta bună</h3>
                  <p>Savurează produsele comandate</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 offset-lg-1 align-self-center">
            <div class="intro_txt">
              <div class="main_title">
                <span><em></em></span>
                <h2>Convinge-te singur</h2>
              </div>
              <p class="lead">Alege unul dintre restaurantele publicate pe site</p>
              <p>Selecteaza produsele dorite și bucură-te de preparatele savuroase</p>
            </div>
          </div>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
import PublicRestaurants from '@/components/publicEndpoints/restaurants'
import PublicCities from '@/components/publicEndpoints/cities'

import axios from "axios";

export default {
  name: 'Home',
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  data() {
    return {
      restaurants: [],
      slides: [
        {img: '/img/1.jpg'},
        {img: '/img/1.jpg'},
        {img: '/img/1.jpg'},
        {img: '/img/1.jpg'},
      ],
      cities: [],
      paging: {
        page: 1,
        per_page: 12,
        total: 10,
        lastPage: 1000
      },
      filters: {},
      searchTerm: '',
      lazyLoadingBusy: false,
      endOfResults: false,
      isBusy: false,
    }
  },
  methods: {
    search(e) {
      e.preventDefault()
      this.$router.push({ name: 'public.restaurants', params: { searchTerm: this.searchTerm } })
    },
    getRestaurants() {
      const params = {
        page: 1,
        per_page: 8
      }
      PublicRestaurants.getAll(params).then(response => {
        this.restaurants = response.data.data
      })
    },
    askLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.getPosition);
      }
    },
    getPosition(position) {
      const lat = position.coords.latitude
      const lon = position.coords.longitude
      axios.get(process.env.VUE_APP_API_URL+ '/public/get_location/'+lat+'/'+lon).then(response => {
        this.searchTerm = '';
        if (response.data.route) {
          this.searchTerm += response.data.route;
        }
        if (response.data.street_number) {
          this.searchTerm += ' ' + response.data.street_number
        }
        if (response.data.locality) {
          this.searchTerm += ', ' + response.data.locality
        }
      })
    },
    getCities(clearResults = false) {
      if (this.endOfResults || this.isBusy) {
        return;
      }
      this.isBusy = true;
      PublicCities.getAll(this.paging, this.filters).then((response) => {
        if (response.data.data.length === 0) {
          this.endOfResults = true;
        }
        if (clearResults) {
          this.cities = response.data.data;
        } else {
          response.data.data.forEach((city) => {
            if (!this.cityExists(city.id)) {
              this.cities.push(city);
            }
          });
        }
        this.paging.total = response.data.meta.total;
        this.paging.lastPage = response.data.meta.last_page;
        this.isBusy = false;
        this.lazyLoadingBusy = false;
      });
    },
    handleScroll: function (e) {
      if (this.lazyLoadingBusy) {
        return;
      }
      const bodyHeight = e.target.body.offsetHeight;
      const innerHeight = window.innerHeight;
      const scrollY = window.scrollY;
      const beforeBottom = document.querySelector('.cities-wrapper') ? document.querySelector('.cities-wrapper').offsetTop : 800;
      const overSize = 200;
      if (innerHeight + scrollY + overSize >= bodyHeight - beforeBottom) {
        this.lazyLoadingBusy = true;
        this.paging.page++;
        this.getCities();
      }
    },
    cityExists(id) {
      return this.cities.some((el) => {
        return el.id === id;
      });
    },
  },
  mounted() {
    // this.getRestaurants()
    this.getCities(true)
    // this.askLocation()
  },
  metaInfo() {
    return {
      title: 'CORESSY delivery - Livram pentru tine',
      meta: [
        { name: 'description', content: 'Comanda preparate gustoase iar CORESSY delivery ti le va livra in cel mai scurt timp'},
        { property: 'og:title', content: 'Coressy Delivery livreaza pentru tine'},
        { property: 'og:site_name', content: 'Coressy Delivery'},
        { property: 'og:description', content: 'Comanda preparate gustoase iar CORESSY delivery ti le va livra in cel mai scurt timp'},
        /* { property: 'og:type', content: 'profile'}, */
        /* { property: 'og:url', content: '' }, */
        { property: 'og:image', content: 'https://coressy.com' + process.env.VUE_APP_LOGO_PATH }
      ]
    }
  }
}
</script>
