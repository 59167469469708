import axios from '@/components/plugins/axios'
const url = process.env.VUE_APP_API_URL

class Restaurants {
  get (slug) {
    return axios.get(url + '/public/restaurants/' + slug)
  }

  getAll (params, filters = null) {
    let options = '?page=' + params.page + '&per_page=' + params.per_page
    if (typeof (params.search) !== 'undefined') {
      options += '&search=' + params.search
    }
    if (filters !== null) {
      Object.keys(filters).forEach(key => {
        options += '&filters[' + key + ']=' + filters[key]
      })
    }
    return axios.get(url + '/public/restaurants' + options)
  }
}
export default new Restaurants()
